import React, { useState } from 'react';
import { FormattedMessage } from "react-intl";
import SimpleLocalize from "../../SimpleLocalize";

const CiclosVegetativos = (props) => {
    let camino = '..'
	let poslang = '';
    if(props.pageContext.language == 'en'){
        camino = '../..';
		poslang = "_en";
    }

    const items = [
        { img: `${camino}/clima/ciclos_malbec${poslang}.jpg`, label: "MALBEC" },
        { img: `${camino}/clima/ciclos_cabernet_sauvignon${poslang}.jpg`, label: "CABERNET SAUVIGNON" },
        { img: `${camino}/clima/ciclos_cabernet_franc${poslang}.jpg`, label: "CABERNET FRANC" },
        { img: `${camino}/clima/ciclos_chardonnay${poslang}.jpg`, label: "CHARDONNAY" }
    ]
    const [activeIndex, setActiveIndex] = useState(0);

    const handleOnClick = (index) => {
        setActiveIndex(index);
    }
    return (
        <SimpleLocalize {...props}>
            <section className="py-0 text-center position-relative min-vh-100">
                <div className="bg-holder bg-white no-top">
                    <div className="min-vh-100 container">
                        <div className="row">
                            <div className="col-md-4 text-start pt-4 texto-con-scroll">
                                <h1 className="title-2 mt-3 pt-2"><FormattedMessage id="ciclos-vegetativos-estadios-fenologicos" defaultMessage="ciclos-vegetativos-estadios-fenologicos" /></h1>
                                <hr />
                                <nav className="navbar-expand-lg navbar-light">
                                    <div id="navbarNavAltMarkup">
                                        <div className="navbar-nav flex-wrap">
                                            {items.map((item, index) => {
                                                return (<a key={index} className={activeIndex == index ? "nav-item nav-link active" : "nav-item nav-link unactive"} onClick={() => handleOnClick(index)}>{item.label}</a>)
                                            })}
                                        </div>
                                    </div>
                                </nav>
                                <p className="mt-4">
                                    <FormattedMessage id="cada-variedad-se-comporta" defaultMessage="cada-variedad-se-comporta" />.
                                </p>
                                <p>
                                <FormattedMessage id="cada-anio-tiene-su-particularidad" defaultMessage="cada-anio-tiene-su-particularidad" />.
                                </p>
                            </div>
                            <div className="col-md-8 mt-5">
                                {items.map((item, index) => {
                                    return (<img key={index} className={activeIndex == index ? "d-block img-fluid" : "d-none"} src={item.img} />)
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </SimpleLocalize>
    );
};
export default CiclosVegetativos;