import React, { useState } from 'react';
import { FormattedMessage } from "react-intl";
import SimpleLocalize from "../../SimpleLocalize";
import Section from '../../common/Section';
const CiclosVegetativos = (props) => {
    let camino = '..'
	let poslang = '';
    if(props.pageContext.language == 'en'){
        camino = '../..';
		poslang = "_en";
    }

    const items = [
        { img: `${camino}/clima/ciclos_malbec${poslang}.jpg`, label: "MALBEC" },
        { img: `${camino}/clima/ciclos_cabernet_sauvignon${poslang}.jpg`, label: "CABERNET SAUVIGNON" },
        { img: `${camino}/clima/ciclos_cabernet_franc${poslang}.jpg`, label: "CABERNET FRANC" },
        { img: `${camino}/clima/ciclos_chardonnay${poslang}.jpg`, label: "CHARDONNAY" }
    ]
    const [activeIndex, setActiveIndex] = useState(0);

    const handleOnClick = (index) => {
        setActiveIndex(index);
    }
    return (
        <SimpleLocalize {...props}>
            <Section sectionClasses="py-0 text-center position-relative mb-3" childClasses='bg-white no-top'>
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-start pt-4">
                            <h1 className="title-2 mt-3 pt-2"><FormattedMessage id="ciclos-vegetativos-estadios-fenologicos" defaultMessage="ciclos-vegetativos-estadios-fenologicos" /></h1>
                            <hr />

                            <p className="mt-4">
                                <FormattedMessage id="cada-variedad-se-comporta" defaultMessage="cada-variedad-se-comporta" />.
                            </p>
                            <p>
                            <FormattedMessage id="cada-anio-tiene-su-particularidad" defaultMessage="cada-anio-tiene-su-particularidad" />.
                            </p>
                            <nav className="navbar-expand-lg navbar-light">
                                <div id="navbarNavAltMarkup">
                                    <div className="navbar-nav flex-wrap d-flex flex-row">
                                        {items.map((item, index) => {
                                            return (<a key={index} className={activeIndex == index ? "nav-item nav-link active" : "nav-item nav-link unactive"} onClick={() => handleOnClick(index)}>{item.label}</a>)
                                        })}
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-12">
                            {items.map((item, index) => {
                                return (<img key={index} className={activeIndex == index ? "d-block img-fluid" : "d-none"} src={item.img} />)
                            })}
                        </div>
                    </div>
                </div>
            </Section>
        </SimpleLocalize>
    );
};
export default CiclosVegetativos;