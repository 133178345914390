import React from 'react';
import { FormattedMessage } from "react-intl";
import Section from '../../common/Section';
import img01 from "../../../images/clima-01.jpg"

const VideoHeader = () => {
    return (
        <Section id="seccion1" sectionClasses={"py-0 text-center position-relative min-vh-100"} childClasses="header-video" childStyle={{backgroundImage: "url(" + img01 + ")" }}>
            <div className="video-wrapper position-absolute w-100 h-100">
                <iframe
                    title="video introduction"
                    frameBorder="0"
                    height="100%"
                    width="100%"
                    src="https://youtube.com/embed/STArIHUqHiU?autoplay=1&controls=0&showinfo=0&autohide=1&rel=0&loop=1&mute=1&playlist=STArIHUqHiU&modestbranding=1"
                >
                </iframe>
            </div>
            <div className="min-vh-100 content-wrapper d-flex flex-column justify-content-center text-white container">
                <div style={{ "width": "100%", "height": "100vh", "background": "transparent", "position": "absolute", "zIndex": "1", "left": "0" }}></div>
                <h1 style={{ "zIndex": "2" }}><FormattedMessage id="clima-title" defaultMessage="clima-title" /></h1>
                <p style={{ "zIndex": "2" }} className="col-8 offset-2 text-center">
                    <FormattedMessage id="nuestra-finca-cuenta-con-un-clima-excepcional" defaultMessage="nuestra-finca-cuenta-con-un-clima-excepcional" />.
                </p>
            </div>
        </Section>
    );
};

export default VideoHeader;