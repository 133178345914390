import React from 'react';
import img01 from "../../../images/clima-01.jpg"
import VideoHeader from '../../common/mobile/VideoHeader';

const VideoHeaderClima = () => {
    return (
        <>
            <VideoHeader
                youtubeurl='https://youtube.com/embed/STArIHUqHiU?autoplay=1&controls=0&showinfo=0&autohide=1&rel=0&loop=1&mute=1&playlist=STArIHUqHiU&modestbranding=1'
                titleText='clima-title'
                descriptionText='nuestra-finca-cuenta-con-un-clima-excepcional'
                backimg={img01}
            />
        </>
    );
};

export default VideoHeaderClima;