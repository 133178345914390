import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import DesktopTemperaturasDelValle from "../components/clima/desktop/temperaturasdelvalle";
import MobileTemperaturaDelValle from "../components/clima/mobile/TemperaturaDelValle";
import DesktopCiclosVegetativos from "../components/clima/desktop/ciclosvegetativos";
import MobileCiclosVegetativos from "../components/clima/mobile/Ciclosvegetativos";
import DesktopVideoHeader from "../components/clima/desktop/VideoHeader";
import MobileVideoHeader from "../components/clima/mobile/VideoHeader";
import SimpleLocalize from "../components/SimpleLocalize";
import DesktopSection2 from "../components/clima/desktop/Section2";
import MobileSection2 from "../components/clima/mobile/Section2";
import DesktopHeliofania from "../components/clima/desktop/heliofania";
import MobileHeliofania from "../components/clima/mobile/Heliofania";

const MobilePage = (props) => {
  return (
    <>
      <MobileVideoHeader />
      <MobileSection2 />
      <MobileHeliofania {...props} />
      <MobileTemperaturaDelValle {...props} />
      <MobileCiclosVegetativos {...props} />
    </>
  );
};

const DesktopPage = (props) => {
  return (
    <>
      <DesktopVideoHeader />
      <DesktopSection2 />
      <DesktopHeliofania {...props} />
      <DesktopTemperaturasDelValle {...props} />
      <DesktopCiclosVegetativos {...props} />
    </>
  );
};

const IndexPage = (props) => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };

      handleResize();

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  const isDesktop = !isMobile;

  return (
    <Layout {...props}>
      <SimpleLocalize {...props}>
        {isDesktop ? <DesktopPage {...props} /> : <MobilePage {...props} />}
      </SimpleLocalize>
    </Layout>
  );
};

export default IndexPage;
