import React from 'react';
import { FormattedMessage } from "react-intl";
import img02 from "../../../images/clima-02.jpg"
import Section from '../../common/Section';

const Section2 = () => {
    return (
        <Section id="seccion2" sectionClasses={"py-0 position-relative min-vh-100"} childStyle={{ background: "#FFF"}} childClasses={'pt-5'}>
        <div className="min-vh-100 content-wrapper container">
            <div className="row">
                <div className="col-12 ps-4 pe-4">
                    <h1 className="title-2 mb-3" style={{ maxWidth: '100%' }}><FormattedMessage id="clima-del-valle-de-uco" defaultMessage="clima-del-valle-de-uco" /></h1>
                    <h4><FormattedMessage id="nuestro-clima" defaultMessage="nuestro-clima" /></h4>
                    <p><FormattedMessage id="un-viniedo-ubicado-en-una-latitud" defaultMessage="un-viniedo-ubicado-en-una-latitud" />.</p>
                    <p><FormattedMessage id="de-grado-winkler" defaultMessage="de-grado-winkler" />.</p>
                    <p><FormattedMessage id="de-una-amplitud-termina" defaultMessage="de-una-amplitud-termina" />.</p>
                    <p><FormattedMessage id="gracias-a-esta-amplitud" defaultMessage="gracias-a-esta-amplitud" />.</p>
                </div>
                <div className="col-12">
                    <img src={img02} alt="" className="img-fluid" />
                </div>
            </div>
        </div>
        </Section>
    );
};

export default Section2;